import type { CartConfig } from '#types/config/components/cart'
import type { RecursivePartial } from '#types/utils'

export default {
  brandClasses: {
    header: 'b-grey-70 mb-4 lg:mb-6',
    items: 'pb-8 md:pb-10',
    noItems: '-mt-4 lg:-mt-6 flex center text-center subtitle-3'
  },
  brandStyles: {
    noItems: {
      h: {
        sm: '11.5rem',
        md: '15.375rem',
        lg: '15.875rem'
      }
    }
  },
  coupon: {
    buttonVariant: 'tertiary'
  },
  disableBonusPdpLinks: true,
  item: {
    actionMenu: {
      brandClasses: {
        button: 'py-3 px-0 md:py-4 gap-3',
        list: 'divide-grey-70 px-4'
      }
    }
  },
  outOfStock: {
    showRemoveAll: false,
    showSaveForLater: false
  }
} satisfies RecursivePartial<CartConfig>
