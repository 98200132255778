import type { CMSConfig } from '#types/config/components/cms'

export default {
  brandClasses: {
    featured: 'title-5'
  },
  presets: {
    red: 'c-brand-2',
    green: 'c-green-30',
    search: 'fw-bold'
  }
} satisfies CMSConfig['megaMenuL3Desktop']
