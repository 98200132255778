import type { CMSConfig } from '#types/config/components/cms'

export default {
  brandClasses: {
    title: 'px-6 py-3 b-t b-grey-70 lh-4',
    featured: 'fw-bold'
  },
  presets: {
    red: 'c-brand-2',
    green: 'c-green-30',
    search: 'fw-bold'
  }
} satisfies CMSConfig['megaMenuL3Mobile']
