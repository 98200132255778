import type { CMSConfig } from '#types/config/components/cms'

export default {
  brandClasses: {
    title: 'title-3',
    shopAll: 'title-4 pb-3 pt-1 underlined'
  },
  presets: {
    red: 'c-brand-2',
    green: 'c-green-30',
  }
} satisfies CMSConfig['megaMenuL1Mobile']
