import type { CMSConfig } from '#types/config/components/cms'

export default {
  brandClasses: {
    root: 'space-y-2',
    title: 'title-4 hover:underlined',
    ul: 'space-y-2'
  },
  presets: {
    red: 'c-brand-2',
    green: 'c-green-30',
  }
} satisfies CMSConfig['megaMenuL2Desktop']
