import type { CMSConfig } from '#types/config/components/cms'
import type { RecursivePartial } from '#types/utils'

export default {
  brandClasses: {
    column: 'space-y-6',
    columns: 'mt-4 px-0',
    link: 'px-7',
    popover: 'relative group',
    shopAll: 'title-4 hover:underlined',
    title: 'title-4',
    visualSelectors: 'mt-4 gap-20',
    visualSelectorsTitle: '',
    visualSelectorsTitleWrapper: 'mt-8'
  },
  underlineOffset: '-0.35rem',
  presets: {
    red: {
      menu: 'c-brand-2',
      underline: 'bg-brand-2'
    },
    green: {
      menu: 'c-green-30',
      underline: 'bg-green-30'
    },
  }
} satisfies RecursivePartial<CMSConfig['megaMenuL1Desktop']>
