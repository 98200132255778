import type { RecursivePartial } from '#types/utils'
import type { SignUpConfig } from '#types/config/components/form/signUp'

export default {
  agreementCheckboxes: [
    {
      type: 'termsConditionsAndPolicy',
      locales: [
        'cs-cz',
        'da-dk',
        'de-at',
        'de-ch',
        'de-lu',
        'en-ch',
        'en-dk',
        'en-fi',
        'en-lu',
        'en-se',
        'fr-be',
        'fr-ch',
        'fr-lu',
        'it-ch',
        'nl-be',
        'nl-lu',
        'pl-pl',
        'pt-pt',
        'sv-fi',
        'sv-se'
      ],
      required: true
    },
    {
      type: 'loyaltyTerms',
      locales: ['en-gb', 'it-it', 'en-ie', 'fr-fr', 'de-de', 'nl-nl', 'es-es'],
      required: true
    },
    {
      type: 'newsletter'
    }
  ],
  gender: {
    options: [
      { value: 'M', label: 'male' },
      { value: 'F', label: 'female' },
      { value: 'NA', label: 'preferNotToAnswer' }
    ]
  },
  isBirthDateRequired: false,
  isSmsOptInRequired: false,
  loyaltyAgreementCheckboxes: [
    {
      type: 'loyaltyTerms',
      locales: ['en-us', 'en-ca', 'fr-ca', 'de-de', 'fr-fr', 'es-es', 'en-gb', 'it-it', 'nl-nl', 'en-ie'],
      required: true
    },
    {
      type: 'policy',
      locales: ['en-us', 'en-ca', 'fr-ca'],
      required: true
    }
  ],
  minimalAge: 18,
  mobilePhone: {
    enableCountryCodeSelection: true
  },
  onSuccessNavigateTo: '/account',
  showBirthDateField: true,
  showSmsSectionLocales: ['en-us', 'en-ca', 'fr-ca']
} satisfies RecursivePartial<SignUpConfig>
